.searchContainer {
  position: relative;

  .editActions {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 16px;
    background-color: #e3ffd2;
    > * + * {
      margin-left: 12px;
    }
  }
}

.tag {
  display: inline-block;
  margin-right: 4px;

  &:last-child {
    margin-right: 0;
  }
}

.costColumn {
  padding: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .outOfStock {
    line-height: 49px;
    padding: 0 24px;
    white-space: nowrap;
  }

  .cost {
    position: relative;
    cursor: pointer;

    .currencySymbol {
      position: absolute;
      line-height: 49px;
      left: 24px;
    }

    > input {
      height: 49px;
      width: 100%;
      border: none;
      padding: 0 24px 0 32px;
      background-color: transparent;
      outline: none;
      cursor: inherit;
      color: inherit;
    }
  }
}

.checkboxColumn {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.dirty {
  background-color: #e3ffd2;

  td {
    background-color: #e3ffd2 !important;
  }
}

.editing {
  background-color: #e3ffd2;

  td {
    background-color: #e3ffd2 !important;
  }

  .cost {
    cursor: default;
    color: rgb(21, 161, 84);

    > input {
      cursor: text;
    }
  }
}

.filter {
  height: 25px !important;
  display: block !important;
  background-color: #fff !important;
  margin-right: 12px;

  > div:nth-child(2) {

    > div:first-child {

      > div:nth-child(2) {
        line-height: 25px !important;
        top: 0 !important;
        padding-left: 12px !important;
      }
    }
  }

  button {
    width: 25px !important;
    height: 25px !important;
    padding: 0 !important;
  }

  hr {
    bottom: -2px !important;
  }
}

.actionRowContainer {
  display: flex;
  float: right;
}

.actionContainer {
  display: inline-block;
  vertical-Align: middle;
  align-self: right;
}
