$primaryColor: #15a154;

/**** Colour Palette (ChefHero Brand Styleguide) ***/
$colour-primary-roquefort0:     #F0F7F7;
$colour-primary-roquefort10:    #C8DDDE;
$colour-primary-roquefort20:    #9DC4C7;
$colour-primary-roquefort30:    #78AEB2;
$colour-primary-roquefort40:    #52979D;
$colour-primary-roquefort50:    #2C828A;
$colour-primary-roquefort60:    #076A73;
$colour-primary-roquefort70:    #065961;
$colour-primary-roquefort80:    #04494F;
$colour-primary-roquefort90:    #02383D;
$colour-primary-roquefort100:   #01282B;

$colour-primary-goji0:          #FFF2F3;
$colour-primary-goji10:         #FFD9D9;
$colour-primary-goji20:         #FFBCBF;
$colour-primary-goji30:         #FFA3A7;
$colour-primary-goji40:         #FF8C92;
$colour-primary-goji50:         #FF737A;
$colour-primary-goji60:         #FF5C65;
$colour-primary-goji70:         #D94E56;
$colour-primary-goji80:         #B14147;
$colour-primary-goji90:         #8A3337;
$colour-primary-goji100:        #632629;

$colour-primary-mint0:          #F3F8F6;
$colour-primary-mint10:         #E8F5F0;
$colour-primary-mint20:         #DDEFE8;
$colour-primary-mint30:         #CEE5DB;
$colour-primary-mint40:         #C2E0D3;
$colour-primary-mint50:         #B6D9CA;
$colour-primary-mint60:         #A3CFBC;
$colour-primary-mint70:         #8FB8A7;
$colour-primary-mint80:         #749285;
$colour-primary-mint90:         #5B7369;
$colour-primary-mint100:        #41534C;

$colour-secondary-sage0:        #EBF4F5;
$colour-secondary-sage10:       #C7D7D9;
$colour-secondary-sage20:       #A9BCBE;
$colour-secondary-sage30:       #8DA4A6;
$colour-secondary-sage40:       #728A8E;
$colour-secondary-sage50:       #557378;
$colour-secondary-sage60:       #3A5B5E;
$colour-secondary-sage70:       #304C4F;
$colour-secondary-sage80:       #284043;
$colour-secondary-sage90:       #1F3336;
$colour-secondary-sage100:      #162528;

$colour-secondary-foil0:        #FAFAFA;
$colour-secondary-foil10:       #F5F5F5;
$colour-secondary-foil20:       #DADEDC;
$colour-secondary-foil30:       #BCC2BF;
$colour-secondary-foil40:       #A8AFAC;
$colour-secondary-foil50:       #919996;
$colour-secondary-foil60:       #7D8582;
$colour-secondary-foil70:       #68706D;
$colour-secondary-foil80:       #535B58;
$colour-secondary-foil90:       #404744;
$colour-secondary-foil100:      #2D3330;

$colour-secondary-kale0:        #F2FCF8;
$colour-secondary-kale10:       #D0F0E3;
$colour-secondary-kale20:       #B0DDCB;
$colour-secondary-kale30:       #91CEB6;
$colour-secondary-kale40:       #77C2A1;
$colour-secondary-kale50:       #59B58C;
$colour-secondary-kale60:       #3EA777;
$colour-secondary-kale70:       #348C64;
$colour-secondary-kale80:       #2C7453;
$colour-secondary-kale90:       #235940;
$colour-secondary-kale100:      #1A412F;

$colour-tertiary-yamfries0:     #FFF6F0;
$colour-tertiary-yamfries10:    #FFE7D6;
$colour-tertiary-yamfries20:    #FFD8BD;
$colour-tertiary-yamfries30:    #FFC9A3;
$colour-tertiary-yamfries40:    #FFBD8A;
$colour-tertiary-yamfries50:    #FFAE70;
$colour-tertiary-yamfries60:    #FFA257;
$colour-tertiary-yamfries70:    #DB8B4B;
$colour-tertiary-yamfries80:    #B1723C;
$colour-tertiary-yamfries90:    #8C5A30;
$colour-tertiary-yamfries100:   #634221;

$colour-tertiary-banana0:       #FFFEEE;
$colour-tertiary-banana10:      #FFFDD9;
$colour-tertiary-banana20:      #FFF9C4;
$colour-tertiary-banana30:      #FFF6A8;
$colour-tertiary-banana40:      #FFF291;
$colour-tertiary-banana50:      #FFEF78;
$colour-tertiary-banana60:      #FFEC5E;
$colour-tertiary-banana70:      #D9C950;
$colour-tertiary-banana80:      #B1A443;
$colour-tertiary-banana90:      #8C8235;
$colour-tertiary-banana100:     #635C28;

$colour-tertiary-blueberry0:    #EDF9FF;
$colour-tertiary-blueberry10:   #DEF4FF;
$colour-tertiary-blueberry20:   #C9EDFF;
$colour-tertiary-blueberry30:   #B2E5FF;
$colour-tertiary-blueberry40:   #9BDDFE;
$colour-tertiary-blueberry50:   #81D4FE;
$colour-tertiary-blueberry60:   #69CDFF;
$colour-tertiary-blueberry70:   #4BA8D6;
$colour-tertiary-blueberry80:   #4289AD;
$colour-tertiary-blueberry90:   #356F8C;
$colour-tertiary-blueberry100:  #284F63;

$colour-tertiary-eggplant0:     #EEEEFF;
$colour-tertiary-eggplant10:    #D9D9FF;
$colour-tertiary-eggplant20:    #C4C4FF;
$colour-tertiary-eggplant30:    #A8A8FF;
$colour-tertiary-eggplant40:    #9191FF;
$colour-tertiary-eggplant50:    #7878FF;
$colour-tertiary-eggplant60:    #6969FF;
$colour-tertiary-eggplant70:    #5050D9;
$colour-tertiary-eggplant80:    #4343B1;
$colour-tertiary-eggplant90:    #35358C;
$colour-tertiary-eggplant100:   #282863;

:export {
    colourprimaryroquefort60: $colour-primary-roquefort60;
}
