.orderProductsTable {
    width: 100%;
}

.orderProductQuantity {
    text-align: right;
    width: 64px !important;
}

.sectionTitle {
    margin-top: 30px;
}

.notesTextBox {
    width: 100%;
    height: 125px;
}

.confirmationLabel {
    color: black;
}

.confirmationValue {
    margin-left: 40px;
}

.disputedFlow {
    color: rgb(112, 128, 144);
    font-size: 14px;
    font-style: italic;
    padding-top: 15px;
}

.prefilledQuantityIcon {
    margin-right: '5px'; 
    color: '#B7B7B7';
    font-size: '18px';
    float: 'left';
}

.disputedItem {
    color: rgb(255, 0, 0);
    font-size: 14px;
}

tr {
    border-bottom: 1px !important;
}

tr:last-child {
    border-bottom: none !important;
}