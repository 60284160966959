@import '../../styles/colors.scss';

.invoiceTableContainer {
    padding: 20px;
}

.viewOriginalOrder {
    margin-bottom: 24px;
}

.actions {
    margin-top: 12px;
    min-height: 36px;
    >* {
        margin-left: 12px;
    }
}

.reasonShow {
    display: block !important;
}

.reasonHide {
    display: none !important;
}

.loader {
    text-align: center;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
}

.invoiceButtons {
    position: absolute; 
    top: 115px;
    right: 46px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.connectChip {
    background-color: $colour-tertiary-blueberry70 !important; // override default behaviour from material-ui
}