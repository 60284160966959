@import '../../styles/colors';

.container {
  line-height: 64px;
  height: 64px;
  background-color: $colour-primary-roquefort80 !important;
  padding-left: 10px !important;

  .text {
    display: flex;
    align-items: center;
  }

  .logo {
    margin: 2px 16px 0 24px;

    img {
      height: 27px;
      position: relative;
      top: -5px;
    }
  }

  .title {
    font-size: 18px;
  }

  > div {
    margin: 0 !important;
    padding: 0 !important;
  }

  > button:first-child {
    display: none !important;
  }

  .btn {
    color: #fff !important;

    span {
      text-transform: capitalize !important;
      font-size: 16px !important;
      font-weight: none !important;
    }
  }
}

@media screen and (max-width: 480px)  {
  .container {
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: $colour-primary-roquefort80 !important;
   
    .logo {
      > img {
        display: none;
      }
    }

    > div {
      font-size: 18px !important;

      button {
        height: 64px;
        vertical-align: middle;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}