.inventoryItems {
  border: 1px solid #e0e0e0;
}

.quantityContainer {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  width: 1px;
}

.quantity {
  height: 26px;
  width: 200px;
  border: 1px solid #999;
  font-size: 13px;
  padding: 0 6px;
  font-weight: 700;
}
