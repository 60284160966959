.head {
  background-color: rgb(232, 232, 232);
  padding: 10px 16px;
  display: table;
  width: 100%;

  > * {
    display: table-cell;
    vertical-align: top;
  }
}

.subheader
{
  background-color: rgb(232, 232, 232);
  padding: 5px 16px;
  padding-top: 0px;
  display: table;
  width: 100%;

  > * {
    display: table-cell;
    vertical-align: top;
  }
}

.title {
  color: rgba(0, 0, 0, 0.55);
  font-size: 18px;
}

.subtitle {
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
}

.actions {
  width: 1px;
  white-space: nowrap;
}

.field {
  padding: 12px 24px;
  border-bottom: 1px solid rgb(224, 224, 224);
  display: table;
  width: 100%;
  font-size: 14px;

  &:last-child {
    border-bottom: 0;
  }

  > * {
    display: table-cell;
    vertical-align: top;
  }
}

.label {
  width: 220px;
}

.value {
}
