.products {
    width: 100%;
    thead {
        tr {
            th {
                color: rgba(0, 0, 0, 0.55);
                font-size: 16px;
                padding: 12px;
                background-color: #e8e8e8;
                font-weight: 400;
            }
        }
    }
    tbody {
        tr {
            border-top: 1px solid #e8e8e8;
            &:first-child {
                border-top: none;
            }
            td {
                padding: 12px;
                font-size: 14px;
            }
            .taxable {
                font-size: 24px;
                line-height: 30px;
            }
        }
        .quantity,
        .price {
            padding: 0;
        }
    }
}

.productTotal {
    font-size: 18px;
    font-weight: 500;
}

.subtotalLabel,
.taxLabel,
.totalLabel {
    text-align: right;
}

.totalLabel {
    font-size: 20px;
    font-weight: 600;
}

.total {
    font-size: 20px;
    font-weight: 600;
}

.actions {
    margin-top: 12px;
    min-height: 36px;
    >* {
        margin-left: 12px;
    }
}

.notes {
    border-right: 1px solid #e8e8e8;
    position: relative;
    .label {
        color: #666;
    }
    .value {
        overflow: auto;
        width: 100%;
        height: 100px;
        background-color: transparent;
        resize: none;
        padding: 5px 10px;
        border-radius: 4px;
        border-color: #888;
    }
}

.disputedItem {
    color: rgb(255, 0, 0);
    font-size: 14px;
    padding-top: 15px;
}

.editActions {
    border-top: 1px solid rgb(224, 224, 224);
    padding: 12px 24px;
    >* {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.checkbox {
    width: 1px;
    padding-right: 0 !important;
    >div>div>div {
        margin-right: 0 !important;
    }
}

.productNotes {
    color: #666;
    font-size: 14px;
}

.productActions {
    width: 1px;
    white-space: nowrap;
    >* {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.notesActions {
    position: absolute;
    top: 4px;
    right: 24px;
    >* {
        margin-left: 12px;
    }
}

.notesError {
    position: absolute;
    top: 12px;
    left: 100px;
}