.filters {
  padding: 12px 24px;
}

.vendor, .buyer {
  margin: 20px 0;
  height: 34px;
}

.replacementFilter {
  margin-top: -15px;
  margin-bottom: 15px;
}

.country {
  margin-top: 24px;
}

.dateRange {

  .value {

    > * {
      display: inline-block;
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.label {
  font-weight: 500;
  text-align: right;
  margin: auto 0;
}

.countryLabel {
  display: inline-block;
  font-weight: 500;
  vertical-align: middle;
  text-align: right;
  line-height: 36px;
}

.value {
  margin: auto 0;
}

.timeframe {
  .timeframeSelect {
    height: auto !important;
    width: 100% !important;

    div {
      margin-top: 0 !important;
    }
  }
}

.statusesContainer {
  margin-top: 2px;

  .label {
    margin-top: 16px;
  }
}

.statuses {
  margin: 0 -24px;

  > * {
    margin-top: 12px;
  }
}

.status {
  display: inline-block;
  vertical-align: top;
  width: 100px;
  text-align: center;

  .count {
    border: 1px solid #666;
    width: 50px;
    height: 34px;
    margin: 0 auto;
    line-height: 34px;
    color: #333;
    font-size: 20px;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      left: -26px;
      width: 26px;
      top: 17px;
      border-top: 1px solid #666;
    }

    &:after {
      content: '';
      position: absolute;
      right: -26px;
      width: 26px;
      top: 17px;
      border-top: 1px solid #666;
    }
  }

  .statusLabel {
    color: #555;
    font-size: 12px;
    line-height: 1.3;
    padding: 0 6px;
    margin-top: 6px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }

  &:first-child {

    .count {

      &:before {
        display: none;
      }
    }
  }

  &:last-child {

    .count {

      &:after {
        display: none;
      }
    }
  }

  &.selected {

    .count {
      border-color: rgb(21, 161, 84);
      color: #fff;
      background-color: rgb(21, 161, 84);
    }

    .statusLabel {
      color: rgb(21, 161, 84);
    }
  }
}

.cancelledStatus {

  .count {
    
    &:before {
      left: -51px;
      width: 50px;
      border-top: 1px solid #FFF;
    }
  }
}

.date {

  > div:first-child {
    height: 26px !important;

    hr {
      bottom: -2px !important;
    }

    input {
      text-align: center !important;
    }
  }
}

.text {
  height: 25px !important;

  hr {
    bottom: -2px !important;
  }
}

.orders {
  line-height: 1.3;
}

@media screen and (max-width: 980px) {
  .timeframe {
    .timeframeSelect {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 480px)  {
  .dateRange {
    .value {

      > * {
        width: 100%;
      }
    }
  }
}

