.container {
  padding: 24px 36px 36px;

  h1 {
    font-size: 24px;
    font-weight: 400;
    color: #555;
    margin: 12px 0 24px;
  }

  section + section {
    margin-top: 36px;
  }
}

@media screen and (max-device-width: 480px)  {
  .container {
    h1 {
      font-size: 18px;
    }
  }
}
