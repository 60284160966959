@import '../../styles/colors.scss';

.loader {
    text-align: center;
}

.viewOriginalOrder {
    margin-bottom: 24px;
}

.field {
    &+.field {
        margin-top: 12px;
    }
    .label {
        font-weight: 500;
        text-align: right;
    }
    .select {
        height: 25px !important;
        >div:nth-child(2) {
            >div:first-child {
                >div:nth-child(2) {
                    line-height: 25px !important;
                    top: 0 !important;
                }
            }
        }
        button {
            width: 25px !important;
            height: 25px !important;
            padding: 0 !important;
        }
        hr {
            bottom: -2px !important;
        }
    }
    .text,
    .time {
        height: 25px !important;
        >div:first-child {
            bottom: 0 !important;
        }
        hr {
            bottom: -2px !important;
        }
    }
    .multilineText {
        top: -12px !important;
    }
}

.sections {
    >section {
        margin-bottom: 36px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    hr {
        margin: 12px 0;
    }
}

.subheading {
    font-weight: 500;
    margin-bottom: 12px;
}

.bgTag {
    display: inline-block;
    margin-left: 12px;
    vertical-align: middle;
    >div {
        >span {
            font-weight: 500 !important;
        }
    }
}

.panelBody {
    padding: 12px 24px;
}

.grid {
    width: 100%;
    padding: 0;
}

.divider {
    border-top: 1px solid #e0e0e0;
    margin: 24px 0;
}

.vendorInfo {
    >* {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border-left: 1px solid #e0e0e0;
        }
        &:last-child:after {
            display: none;
        }
    }
    @media screen and (max-width: 1199px) {
        >* {
            padding-bottom: 18px;
            margin-bottom: 12px;
            &:after {
                top: auto;
                bottom: 0;
                left: 12px;
                right: 12px;
                border-left: none;
                border-top: 1px solid #e0e0e0;
            }
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                &:after {
                    display: none;
                }
            }
        }
    }
}

.field {
    .fieldLabel {
        text-align: right;
        font-weight: 500;
        padding-right: 20px;
        width: 250px;
    }
    .smallFieldLabel {
        text-align: right;
        font-weight: 500;
        padding-right: 36px;
        font-size: 14px;
    }
    button {
        >div {
            >div {
                height: 30px !important;
                >span {
                    font-size: 12px !important;
                    padding-left: 12px !important;
                    padding-right: 12px !important;
                }
            }
        }
    }
}

.grid {
    max-width: 90%;
}

.date {
    >div:first-child {
        height: 26px !important;
        hr {
            bottom: -2px !important;
        }
    }
}

.actions {
    margin-top: 12px;
    >* {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.vendorSection,
.buyerSection {
    .actions {
        padding-top: 12px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -24px;
            right: -24px;
            border-top: 1px solid #e0e0e0;
        }
    }
}

.panelButton {
    height: '28px';
    line-height: '28px';
}

.numberInput {
    input[type='number'] {
        -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.reasonShow {
    display: block !important;
}

.reasonHide {
    display: none !important;
}

.chip {
    // override default Chip behaviour from material-ui
    background-color: $colour-tertiary-blueberry70 !important;
    display: inline-block !important;
    span {
        vertical-align: middle;
    }
}
.thumbImage{
    width: 125px;
    height: 125px;
    object-fit: contain;
}

.thumbImageContainer{
  gap: 10px;
}

.deleteButton {
    display: block !important;
    width: 100%;
    color: $colour-primary-goji70 !important;
  }

  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .loaderCenter {
      @extend .loader;
      width: 100%;
      height: 0px;
  }