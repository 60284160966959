.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
    text-align: right;
}

.head {
    background-color: rgb(232, 232, 232);
    padding: 10px 16px;
    display: table;
    width: 100%;
}

.notRow {  
    > td:first-child:not(:last-child) {
      display: none !important;
    }
}

.title {
    color: rgba(0, 0, 0, 0.55);
    font-size: 18px;
    display: inline-block;
}

.invoiceDates {
    color: rgba(0, 0, 0, 0.55);
    font-size: 15px;
    text-align: right;
    position: absolute;
    top: 10px;
    right: 16px;
}

.body {
    padding: 20px;
}

.sectionHeaders {
    font-size: 18px;
    text-decoration: underline;
    border-bottom-style: none;
}

.reasonWidth {
    width: 10vw !important;
}

.quantityWidth {
    width: 10vw !important;
}

.productWidth {
    width: auto;
}

.center {
    text-align: center !important;
}

.right {
    width: 8vw !important;
    text-align: right !important;
    text-overflow: initial !important;
}

.costLabel {
    width: 8vw !important;
    text-align: right !important;
    text-overflow: initial !important;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.invisible {
    visibility: hidden;
}

.editingActionConfig {
    width: 6vw !important;
}

.invisibleEditingActionConfig {
    width: 6vw !important;
    visibility: hidden;
}

.invisibleActionConfig {
    visibility: hidden;
}

h3 {
    margin-top: 15px;
    font-size: 20px;
}

td {
    position: relative;
}

tr {
    border-bottom: 1px solid #ddd;
}

tr.strikeout td.strikeable:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 100%;
}

tr.strikeout td.strikeable:after {
    content: "\00B7";
    font-size: 1px;
}

.subtotalRow {
    border-top: 2px solid black;
}

.totalRow {
    border-top: 4px solid black;
}