@import '../../styles/colors';

.container {

  > div {
    padding-top: 64px !important;
    z-index: 1000 !important;
    width: 200px !important;
  }

  a {
    text-decoration: none;
  }
}

.heading {
  font-size: 12px;
  color: #666;
  padding: 24px 24px 4px;
}

.divider {
  margin-top: 12px !important;
}

.menuItem {

  > div > div {
    padding: 0 24px !important;
  }

  &.active {
    background-color: $colour-primary-roquefort40 !important;
    color: #fff !important;
  }
}

.accordion {
  line-height: 48px;
  padding: 0 24px !important; 
  font-weight: normal !important;

  &.active {
    background-color: $colour-primary-roquefort40 !important;

    span, svg {
      color: #fff !important;
    }
  }
}

.cardActions {
  padding: 0 !important;

  a {
    margin: 0 !important;
  }

  .menuItem div {
    margin-left: 10px !important;
    font-size: 13px;
  }
}

.footer {
  position: absolute;
  bottom: 12px;
  font-size: 11px;
  color: #ddd;
  width: 100%;
  text-align: center;
  padding: 12px 8px 4px;
}

@media screen and (max-width: 480px)  {
  .container {
    > div {
      width: 150px !important;
    }
  }

  .heading {
    font-size: 10px;
  }

  .menuItem {
    font-size: 14px !important;
  }

  .footer {
    font-size: 10px;
  }

}