.container {
    .loader {
        text-align: center;
        position: absolute;
        top: 48px;
        left: 0;
        right: 0;
    }
    .emptyPlaceholder {
        padding: 8px 16px;
    }
    .vendors {
        padding-top: 8px;
        .vendor {
            background-color: #fff;
            border: none;
            outline: none;
            width: 100%;
            display: block;
            text-align: left;
            padding: 8px 16px;
            &.selected {
                background-color: rgb(21, 161, 84) !important;
                color: #fff !important;
            }
        }
    }
}

.warningMessage {
    color: red;
    text-align: center;
}