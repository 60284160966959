.container {
  padding: 25px 55px;

  div[class^="col-"]:last-child {
    padding: 0 !important;
  }

  div[class^="col-"] {
    padding-right: 20px;
    padding-left: 0px;
  }

  .checkbox {
    margin-top: 15px;
  }

  .title {
    padding: 25px 0;
    font-size: 13px;
    height: 30px;

    label {
      font-weight: 500;
    }
  }

  .maskedInput {
    margin-top: 35px;
    border-bottom: 1px solid #E0E0E0;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    font-size: 16px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .actions {
    margin-top: 20px;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .submit {
      float: right;
    }
  }
  .row {
    .col {
      padding: 10px;
    }  
  }
}
