.field {
    margin-top: 18px;
    .label {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.3);
        margin-bottom: 2px;
    }
    .value {
        color: #000;
    }
}

.reasonShow {
    display: block !important;
}

.reasonHide {
    display: none !important;
}