.container {
  text-align: center;
  position: relative;
  height: 36px;

  .page {
    font-size: 12px;
    color: #777;
  }

  .previousPage, .nextPage {
    position: absolute;
    top: 0;
  }

  .previousPage {
    left: 0;
  }

  .nextPage {
    right: 0;
  }
}