.table {
    display: table;
    width: 100%;
}

.head {
    background-color: rgb(232, 232, 232);
    padding: 10px 16px;
    display: table;
    width: 100%;
}

.title {
    color: rgba(0, 0, 0, 0.55);
    font-size: 18px;
    display: inline-block;
}

.invoiceDates {
    color: rgba(0, 0, 0, 0.55);
    font-size: 15px;
    text-align: right;
    width: 100%;
}

.body {
    padding: 20px;
}

.sectionHeaders {
    font-size: 18px;
    text-decoration: underline;
    border-bottom-style: none;
}

tr {
    border-bottom: 1px solid #ddd;
}

.subtotalRow {
    border-top: 2px solid black;
}

.totalRow {
    border-top: 4px solid black;
}