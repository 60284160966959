.label {
    display: inline-block;
    font-weight: 500;
    padding-right: 36px;
    padding-top: 20px;
    text-align: right;
}

.value {
    padding-top: 12px;
}

.text {
    height: 25px !important;
    hr {
        bottom: -2px !important;
    }
}

.headerWrapper {
    display: flex;
    width: 100%;
    >* {
      flex: 1 1 auto;
      vertical-align: middle;
    }
  }
  

.addAccountWrapper {
    flex: 0 0 auto;
    margin-left: 10px;
}

@media screen and (max-width: 980px) {
    .timeframe {
        .timeframeSelect {
            margin-top: 16px;
        }
    }
}

@media screen and (max-width: 480px) {
    .dateRange {
        .value {
            >* {
                width: 100%;
            }
        }
    }
}

.filterRow {
    margin: 20px auto;
    height: 34px;
}

.filterRow:last-child {
    height: auto;
    margin-bottom: 0;
    padding-bottom: 30px;
}

.adminOnlyCheckboxCol{
    padding-top: 18px;
}