.headerWrapper {
    display: flex;
    width: 100%;
    >* {
        flex: 1 1 auto;
        vertical-align: middle;
    }
}

.vendorFilter {
	bottom: 12px;
}

.addVendorWrapper {
    flex: 0 0 auto;
}

.label {
    display: inline-block;
    font-weight: 500;
    padding-right: 36px;
    padding-top: 15px;
    text-align: right;
}

.filterRow {
    margin: 20px 0;
    height: 34px;
}

.filterRow:last-child {
  height: auto;
  margin-bottom: 0;
  padding-bottom: 30px;
}