.actions {
  margin-bottom: 12px;

  > * {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.headerWrapper {
    display: flex;
    width: 100%;
    >* {
        flex: 1 1 auto;
        vertical-align: middle;
    }
}

.addButtonWrapper {
    flex: 0 0 auto;
}