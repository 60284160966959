@import '../../styles/colors';

.container {
  display: none;
  background-color: $colour-primary-goji10;

  &.active {
    display: block;
  }
}
