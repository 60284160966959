.tag {
    display: inline-block;
    margin-right: 4px;
    &:last-child {
        margin-right: 0;
    }
}

.grid {
    width: 100%;
}

.field {
    &+.field {
        margin-top: 12px;
    }
    .label {
        font-weight: 500;
        text-align: right;
    }
    .select {
        height: 25px !important;
        >div:nth-child(2) {
            >div:first-child {
                >div:nth-child(2) {
                    line-height: 25px !important;
                    top: 0 !important;
                }
            }
        }
        button {
            width: 25px !important;
            height: 25px !important;
            padding: 0 !important;
        }
        hr {
            bottom: -2px !important;
        }
    }
    .text,
    .time {
        height: 25px !important;
        >div:first-child {
            bottom: 0 !important;
        }
        hr {
            bottom: -2px !important;
        }
    }
    .multilineText {
        top: -12px !important;
    }
}

.deliveryTimeWindow,
.deliveryInfo,
.onboardBuyerEmail {
    margin-bottom: 6px;
    &:last-child {
        margin-bottom: 0;
    }
    >* {
        display: inline-block;
        vertical-align: top;
    }
    .number,
    .dayOfWeek {
        margin-right: 12px;
    }
    .dayOfWeek {
        width: 100px;
    }
    .time {
        width: 120px !important;
    }
    .to {
        margin-left: 12px;
        margin-right: 12px;
    }
    .enable {
        margin-left: 24px;
    }
}

.deliveryTimeWindows,
.onboardBuyerEmails {
    margin-bottom: 12px;
}

.defaultVendorRegion {
    background-color: rgba(0, 255, 0, 0.1);
}

.back {
    margin-bottom: 24px;
}

.amountContainer {
    position: relative;
    .transferredAmount {
        position: absolute;
        bottom: 28px;
        left: 0;
        right: 0;
        text-align: center;
        color: #444;
        font-size: 15px;
    }
}

.regionAddButton {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: 12px;
    >* {
        &:last-child {
            float: right;
        }
        &:first-child {
            float: none;
        }
    }
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.actions {
    border-top: 1px solid rgb(224, 224, 224);
    padding: 12px 24px;
    text-align: right
}

.empty {
    margin: 12px 24px;
    font-size: 13px;
}