@import '../../styles/colors';

.loader {
    margin-top: 12px;
    text-align: center;
}

.filters {
    padding: 12px 24px;
}

.label {
    display: inline-block;
    font-weight: 500;
    vertical-align: middle;
    text-align: right;
}

.sections {
    >section {
        margin-bottom: 36px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.dateRange {
    .value {
        >* {
            display: inline-block;
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.orderstatus {
    .orderstatusSelect {
        height: auto !important;
        width: 100% !important;
        div {
            margin-top: 0 !important;
        }
    }
}

.value {
    vertical-align: middle;
}

.date {
    >div:first-child {
        height: 26px !important;
        hr {
            bottom: -2px !important;
        }
        input {
            text-align: center !important;
        }
    }
}

.raised_button {
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
}

.panelBody {
    padding: 12px 24px;
}

.sectionBody {
    padding: 12px 24px;
}

.balanceSection {
    .balancePanelBalanceTitle,
    .balancePanelPaidTitle,
    .balancePanelOverdueTitle {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        >div:first-child {
            color: #fff;
        }
    }
    .balancePanelBalanceTitle {
        background-color: #f8ac59;
    }
    .balancePanelPaidTitle {
        background-color: #1ab394;
    }
    .balancePanelOverdueTitle {
        background-color: #ed5565;
    }
    .amount {
        font-size: 32px;
        text-align: center;
        line-height: 100px;
        padding-bottom: 20px;

        .discrepancyAmount {
            color: #ed5565;
            font-size: 24px;
            padding-left: 5px;
        }

        .discrepancyNote {
            color: #ed5565;
            font-size: 24px;
            vertical-align: super;
            padding-left: 3px;
            text-decoration: underline;

            &:hover {
                cursor: help;
            }
        }

        .discrepancyTooltip {
            text-align: left;
            line-height: 15px;
        }
    }
    
    .actions {
        margin-top: 12px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: row-reverse;
    
        &:after {
            content: '';
            display: table;
            clear: both;
            margin-bottom: 24px;

        }
    }
}

.priceExceptionsSection,
.commentsSection,
.membersSection,
.settingsSection,
.shippingAddressSection {
    .actions {
        border-top: 1px solid rgb(224, 224, 224);
        padding: 12px 24px;
        text-align: right;
        >* {
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.priceExceptionsSection
{
    .tableSection
    {
        margin: 10px 0 30px 0;
    }

    .headerWrapper {
        display: flex;
        width: 100%;
        >* {
            flex: 1 1 auto;
            vertical-align: middle;
        }
    }
    
    .addButtonWrapper {
        flex: 0 0 auto;
    }
}

.settingsSection {
    .grid {
        width: 100%;
    }
    .field {
        &+.field {
            margin-top: 20px;
        }
        .label {
            font-weight: 500;
            text-align: left;
        }
        .select {
            height: 25px !important;
            >div:nth-child(2) {
                >div:first-child {
                    >div:nth-child(2) {
                        line-height: 25px !important;
                        top: 0 !important;
                    }
                }
            }
            button {
                width: 25px !important;
                height: 25px !important;
                padding: 0 !important;
            }
            hr {
                bottom: -2px !important;
            }
        }
        .text {
            height: 25px !important;
            hr {
                bottom: -2px !important;
            }
        }
        // .multilineText {
        //     top: -12px !important;
        // }
    }
    hr {
        border-top: 1px solid #e0e0e0;
    }
}

.billingAddressLabel {
    font-weight: 500;
    height: 48px;
    line-height: 48px;
}

.dropdownMenu {
    max-width: 50%;
    bottom: 24px;
    margin-right: 24px;
}

.accountingEmails {
    margin-bottom: 12px;
    .accountingEmail {
        margin-bottom: 6px;
        &:last-child {
            margin-bottom: 0;
        }
        >* {
            display: inline-block;
            vertical-align: top;
        }
    }
}

.shippingAddressSection {
    .driveInstructions {
        white-space: pre-line !important;
    }
}

.maskedInput {
    margin-top: 15px;
    border-bottom: 1px solid #E0E0E0;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 350px;
}

.submenu {
    list-style-type: none;
    padding: 0;
    display: flex;
    border: 1px solid #ccc;
    margin-bottom: 24px;
    height: 90px;
    line-height: 20px;
    li {
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-right: 1px solid #ccc;
        flex-wrap: wrap;
        padding: 10px;
        text-align: center;
        cursor: pointer;
        display: flex;
        i {
            font-size: 24px;
            width: 100%;
        }
        &:last-child {
            border-right: none;
        }
        &:hover {
            background-color: #e8e8e8;
        }
    }
}

.selected {
    background-color: $colour-primary-roquefort40 !important;
    color: #fff;
}

.buyerSuspended {
  > div:first-child{
    background-color: #FF4E4E !important;
  }
}

@media screen and (max-width: 980px) {
    .balanceSection {
        .amount {
            margin-bottom: 16px;
        }
        .actions {
            margin-bottom: 24px;
        }
    }
}

@media screen and (max-width: 800px) {
    .submenu {
        height: auto;
        line-height: inherit;
        flex-direction: column;
    }
    li {
        width: 100% !important;
        border-bottom: 1px solid #ccc;
        border-right: none !important;
        i {
            margin-right: 8px;
            width: auto !important;
        }
    }
    li:last-child {
        border-bottom: none;
    }
}

.disabledPriceRule {
    background-color: rgba(255, 0, 0, 0.05);
}

.activePriceRule {
    background-color: rgba(0, 255, 0, 0.1);
}

.warning {
    color: #DC3545;
    font-size: 15px;
}