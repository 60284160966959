.columnHeader {
  user-select: none;
  cursor: default;

  &.selectable {
    cursor: pointer;
    text-decoration: underline;
  }

  &.selected {
    color: #15A154;
    font-weight: 500;
  }
}

.notRow {
  height: auto !important;
  border-bottom: none !important;

  > td:first-child:not(:last-child) {
    display: none !important;
  }
}

.sortDirection {
  color: #15A154;
  margin-left: 4px;
}

.paginationWrapper {
  border-top: 1px solid #e0e0e0;
  padding: 12px 24px;
  margin-top: 12px;
}

.loader {
  text-align: center;
  padding: 22px 0;
  div {
    top:0;
    left:0;
  }
}

.intableLoader {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 6px;
}

.idColumn {
  width: 3vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.idColumn:hover{
  overflow: visible; 
  white-space: normal; 
  width: auto;
  position: absolute;
  background-color:#FFF;
  transition: all 500ms ease;
  padding-left: 2px;
  padding-right: 2px;
  box-shadow: inset 0 0 0 1px #99b2be;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
