.grid {
    width: 100%;
    padding: 0;
}

.field {
    .fieldLabel {
        text-align: right;
        font-weight: 500;
        padding-right: 36px;
    }
    .smallFieldLabel {
        text-align: right;
        font-weight: 500;
        padding-right: 36px;
        font-size: 14px;
    }
    button {
        >div {
            >div {
                height: 30px !important;
                >span {
                    font-size: 12px !important;
                    padding-left: 12px !important;
                    padding-right: 12px !important;
                }
            }
        }
    }
    .multilineText {
        top: -12px !important;
    }
}

.orderSection {
    .panelBody {
        padding: 12px 24px;
        .actions {
            overflow: unset;
            margin-top: 12px;
            >* {
                margin-right: 12px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .actions {
        padding-top: 12px;
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -24px;
            right: -24px;
            border-top: 1px solid #e0e0e0;
        }
    }
}