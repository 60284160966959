.comment {
  padding: 12px 24px;

  .from {
    font-size: 14px;
    color: #333;
  }

  .bodyContainer {
    display: table;
    width: 100%;
    margin-top: 6px;

    > * {
      display: table-cell;
      vertical-align: top;
    }

    .body {
      white-space: pre-wrap;
      border: 1px solid #aaa;
      border-radius: 4px;
      padding: 6px 12px;
      background-color: #fff;
    }

    .actions {
      width: 1px;
    }
  }


  .timestamp {
    font-size: 14px;
    color: #666;
    text-align: right;
  }

  & + .comment {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 24px;
      right: 24px;
      border-top: 1px solid #e0e0e0;
    }
  }

  &:hover {
    background-color: #f4f4f4;
  }
}

.grid {
  width: 100%;
  padding: 0;
}

.empty {
  font-size: 13px;
}
