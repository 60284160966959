.invoiceSummaryTable {
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid rgb(224, 224, 224);
    &:last-child {
      border-bottom: none;
    }
  }
  th, td {
    padding: 14px 24px;
  }
  th {
    border-right: 1px solid rgb(224, 224, 224);
    width: 200px;
  }
  .grandTotal {
    font-size: 18px;
    .loss {
      color: #FF0000;
    }
  }
}
