.notesSection {
	.sectionBody {
  		padding: 12px 24px;
	}

	.grid {
	  	width: 100%;
	  	padding: 0;
	}

	.field {

    & + .field {
      margin-top: 12px;
      margin-bottom: 25px;
    }

    .label {
      font-weight: 500;
      text-align: right;
    }

    .text {
      height: 25px !important;

      hr {
        bottom: -2px !important;
      }
    }

    .col {
      padding-left:15px;
    }
  }

  .actions {
    border-top: 1px solid rgb(224, 224, 224);
    padding: 12px 24px;

    > * {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
