.container {
  padding: 25px 55px;

  div[class^="col-"] {
    padding: 0 24px 0 0 !important;

    &:last-child {
      padding-right: 0px !important;
    }
  }

  .checkbox {
    margin-top: 30px;
  }

  .title {
    padding-top: 24px;
    font-size: 13px;

    label {
      font-weight: 500;
    }
  }

  .billing {
    display: none;
  }

  .actions {
    margin-top: 20px;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .submit {
      float: right;
    }
  }
}
