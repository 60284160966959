:global {
    .mdi {
        line-height: 1;
        &:before {
            font-size: 1em;
        }
    }
    .hide {
        display: none;
    }
}

@import '../../styles/constants.scss';
@import '../../styles/colors.scss';
html,
body {
    font-size: 14px;
}

body {
    overflow-x: hidden;
    color: #333;
    font-family: Roboto, sans-serif;
    line-height: 1.6;
    word-wrap: break-word;
    background-color: #f7f7f7;
}

* {
    box-sizing: border-box;
}

button {
    outline: none;
    cursor: pointer;
}

label {
    font-weight: normal;
}

html [type="button"] {
    -webkit-appearance: initial;
}

hr {
    border-top: 1px solid #e0e0e0;
}

.hide {
    display: none;
}

a {
    color: $colour-primary-roquefort100;

    &:focus, &:hover {
        color: $colour-primary-roquefort60;
        text-decoration-color: $colour-primary-goji40;
    }
}

.toastr {
    font-size: 18px;
    font-family: Roboto, sans-serif;
    letter-spacing: 0.8px;
}