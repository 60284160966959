.label {
    pointer-events: none;
    user-select: none;
    font-size: 14px;
    font-weight: bold
}

.value {
    font-size: 10px;
}

.block {
    max-width: 250px;
}

.colInline {
    display: inline-block;
}

.currencySymbol {
    position: absolute;
    line-height: 49px;
    left: 24px;
}

.text {
    height: 19px !important;
    font-size: 13px !important;
    hr {
        bottom: -2px !important;
    }
}

.colLabel {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
}

.colValue {
    display: flex;
    justify-content: center;
    font-size: 12px;
}

.sectionBody {
    margin-top: 25px;
    width: 100%;
    margin-bottom: 25px;
}

div {
    .container {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: auto;
        margin-left: auto;
    }
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.regionFeeWrapper {
    width: 100%;
    margin: 20px 0;
}