
@import '../../styles/colors.scss';
 
.emailStatus {
    color: #fff;
    font-weight: 500;
    text-align: center !important;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    &.open {
        background-color: green !important;
    }
    &.spamReport {
        background-color: red !important;
    }
    &.enqueue {
        background-color: orange !important;
    }
}

.status {
    color: #fff;
    font-weight: 500;
    text-align: center !important;
    background-color: green !important;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}

.rerouteOrderDialog {
    .loader {
        text-align: center;
    }
    .vendor {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
        .title {
            font-weight: 500;
        }
        .detail {
            font-size: 12px;
            line-height: 1.4;
            .label {
                font-weight: 500;
            }
        }
    }
}

.profit,
.loss {
    font-weight: 600;
}

.profit {
    color: #388E3C;
}

.loss {
    color: #f00;
}

.paid {
    color: #fff;
    font-weight: 500;
    text-align: center !important;
    background-color: green !important;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}

.noVendorAvailable {
    padding-left: 16px;
    color: #999;
}

.idColumn {
    white-space: nowrap;
    text-overflow: ellipsis;
    alignment: center;
}

.statusNormal,
.statusError {
    font-weight: 600;
}

.statusNormal {
    color: #388E3C;
}

.statusError {
    color: #f00;
}

.chips {
    margin-top: 8px !important;
}

.connectChip {
    background-color: $colour-tertiary-blueberry70 !important; // override default behaviour from material-ui
}