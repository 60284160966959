.container {
    padding: 50px 15px 15px;
    .createOrderForm {
        margin-left: auto;
        margin-right: auto;
        >h2 {
            margin: 0;
            padding: 80px 55px 10px;
            background-color: #4BA1AA !important;
            color: #fff;
            font-weight: 300;
            font-size: 35px;
        }
    }
}