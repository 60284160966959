/**** Colour Palette (ChefHero Brand Styleguide) ***/
:export {
  colourprimaryroquefort60: #076A73;
}

.loader {
  margin-top: 12px;
  text-align: center;
}

.filters {
  padding: 12px 24px;
}

.label {
  display: inline-block;
  font-weight: 500;
  vertical-align: middle;
  text-align: right;
}

.sections > section {
  margin-bottom: 36px;
}

.sections > section:last-child {
  margin-bottom: 0;
}

.dateRange .value > * {
  display: inline-block;
  margin-right: 12px;
}

.dateRange .value > *:last-child {
  margin-right: 0;
}

.orderstatus .orderstatusSelect {
  height: auto !important;
  width: 100% !important;
}

.orderstatus .orderstatusSelect div {
  margin-top: 0 !important;
}

.value {
  vertical-align: middle;
}

.date > div:first-child {
  height: 26px !important;
}

.date > div:first-child hr {
  bottom: -2px !important;
}

.date > div:first-child input {
  text-align: center !important;
}

.raised_button {
  font-size: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.panelBody {
  padding: 12px 24px;
}

.sectionBody {
  padding: 12px 24px;
}

.balanceSection .balancePanelBalanceTitle,
.balanceSection .balancePanelPaidTitle,
.balanceSection .balancePanelOverdueTitle {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.balanceSection .balancePanelBalanceTitle > div:first-child,
.balanceSection .balancePanelPaidTitle > div:first-child,
.balanceSection .balancePanelOverdueTitle > div:first-child {
  color: #fff;
}

.balanceSection .balancePanelBalanceTitle {
  background-color: #f8ac59;
}

.balanceSection .balancePanelPaidTitle {
  background-color: #1ab394;
}

.balanceSection .balancePanelOverdueTitle {
  background-color: #ed5565;
}

.balanceSection .amount {
  font-size: 32px;
  text-align: center;
  line-height: 100px;
  padding-bottom: 20px;
}

.balanceSection .amount .discrepancyAmount {
  color: #ed5565;
  font-size: 24px;
  padding-left: 5px;
}

.balanceSection .amount .discrepancyNote {
  color: #ed5565;
  font-size: 24px;
  vertical-align: super;
  padding-left: 3px;
  text-decoration: underline;
}

.balanceSection .amount .discrepancyNote:hover {
  cursor: help;
}

.balanceSection .amount .discrepancyTooltip {
  text-align: left;
  line-height: 15px;
}

.balanceSection .actions {
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row-reverse;
}

.balanceSection .actions:after {
  content: '';
  display: table;
  clear: both;
  margin-bottom: 24px;
}

.priceExceptionsSection .actions,
.commentsSection .actions,
.membersSection .actions,
.settingsSection .actions,
.shippingAddressSection .actions {
  border-top: 1px solid #e0e0e0;
  padding: 12px 24px;
  text-align: right;
}

.priceExceptionsSection .actions > *,
.commentsSection .actions > *,
.membersSection .actions > *,
.settingsSection .actions > *,
.shippingAddressSection .actions > * {
  margin-right: 12px;
}

.priceExceptionsSection .actions > *:last-child,
.commentsSection .actions > *:last-child,
.membersSection .actions > *:last-child,
.settingsSection .actions > *:last-child,
.shippingAddressSection .actions > *:last-child {
  margin-right: 0;
}

.priceExceptionsSection .tableSection {
  margin: 10px 0 30px 0;
}

.priceExceptionsSection .headerWrapper {
  display: flex;
  width: 100%;
}

.priceExceptionsSection .headerWrapper > * {
  flex: 1 1 auto;
  vertical-align: middle;
}

.priceExceptionsSection .addButtonWrapper {
  flex: 0 0 auto;
}

.settingsSection .grid {
  width: 100%;
}

.settingsSection .field + .field {
  margin-top: 20px;
}

.settingsSection .field .label {
  font-weight: 500;
  text-align: left;
}

.settingsSection .field .select {
  height: 25px !important;
}

.settingsSection .field .select > div:nth-child(2) > div:first-child > div:nth-child(2) {
  line-height: 25px !important;
  top: 0 !important;
}

.settingsSection .field .select button {
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
}

.settingsSection .field .select hr {
  bottom: -2px !important;
}

.settingsSection .field .text {
  height: 25px !important;
}

.settingsSection .field .text hr {
  bottom: -2px !important;
}

.settingsSection hr {
  border-top: 1px solid #e0e0e0;
}

.billingAddressLabel {
  font-weight: 500;
  height: 48px;
  line-height: 48px;
}

.dropdownMenu {
  max-width: 50%;
  bottom: 24px;
  margin-right: 24px;
}

.accountingEmails {
  margin-bottom: 12px;
}

.accountingEmails .accountingEmail {
  margin-bottom: 6px;
}

.accountingEmails .accountingEmail:last-child {
  margin-bottom: 0;
}

.accountingEmails .accountingEmail > * {
  display: inline-block;
  vertical-align: top;
}

.shippingAddressSection .driveInstructions {
  white-space: pre-line !important;
}

.maskedInput {
  margin-top: 15px;
  border-bottom: 1px solid #E0E0E0;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 350px;
}

.submenu {
  list-style-type: none;
  padding: 0;
  display: flex;
  border: 1px solid #ccc;
  margin-bottom: 24px;
  height: 90px;
  line-height: 20px;
}

.submenu li {
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-right: 1px solid #ccc;
  flex-wrap: wrap;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
}

.submenu li i {
  font-size: 24px;
  width: 100%;
}

.submenu li:last-child {
  border-right: none;
}

.submenu li:hover {
  background-color: #e8e8e8;
}

.selected {
  background-color: #52979D !important;
  color: #fff;
}

.buyerSuspended > div:first-child {
  background-color: #FF4E4E !important;
}

@media screen and (max-width: 980px) {
  .balanceSection .amount {
    margin-bottom: 16px;
  }
  .balanceSection .actions {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 800px) {
  .submenu {
    height: auto;
    line-height: inherit;
    flex-direction: column;
  }
  li {
    width: 100% !important;
    border-bottom: 1px solid #ccc;
    border-right: none !important;
  }
  li i {
    margin-right: 8px;
    width: auto !important;
  }
  li:last-child {
    border-bottom: none;
  }
}

.disabledPriceRule {
  background-color: rgba(255, 0, 0, 0.05);
}

.activePriceRule {
  background-color: rgba(0, 255, 0, 0.1);
}

.warning {
  color: #DC3545;
  font-size: 15px;
}

/**** Colour Palette (ChefHero Brand Styleguide) ***/
:export {
  colourprimaryroquefort60: #076A73;
}

.tag {
  display: inline-block;
  margin-right: 4px;
}

.tag:last-child {
  margin-right: 0;
}

.tabButton {
  color: #fc9527;
}

.paper {
  margin-top: 0px;
}

.sectionTitle {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  padding-top: 20px;
  margin-bottom: 24px;
}

.subSectionTitle {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  padding-top: 20px;
  margin-bottom: 24px;
  font-size: 16px;
}

.settingsSection {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.settingsSection .logo {
  position: relative;
  display: inline-block;
}

.settingsSection .logo img {
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
}

.settingsSection .logo .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: rgba(60, 60, 60, 0.6);
  color: #fff;
  font-size: 65px;
  text-align: center;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  line-height: 200px;
}

.settingsSection .logo .overlay:hover {
  opacity: 1;
}

.settingsSection .grid {
  width: 100%;
}

.settingsSection .field + .field {
  margin-top: 12px;
}

.settingsSection .field .label {
  font-weight: 500;
  display: block;
  text-align: right;
}

.settingsSection .field .select {
  height: 25px !important;
}

.settingsSection .field .select > div:nth-child(2) > div:first-child > div:nth-child(2) {
  line-height: 25px !important;
  top: 0 !important;
}

.settingsSection .field .select button {
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
}

.settingsSection .field .select hr {
  bottom: -2px !important;
}

.settingsSection .field .text,
.settingsSection .field .time {
  height: 25px !important;
}

.settingsSection .field .text > div:first-child,
.settingsSection .field .time > div:first-child {
  bottom: 0 !important;
}

.settingsSection .field .text hr,
.settingsSection .field .time hr {
  bottom: -2px !important;
}

.settingsSection .field .multilineText {
  top: -12px !important;
}

.settingsSection .field .category {
  text-align: center;
  font-weight: 900;
  font-size: 1.1rem;
  padding-left: 0px;
  padding: 8px 0;
}

.settingsSection .deliveryTimeWindow,
.settingsSection .deliveryInfo,
.settingsSection .onboardBuyerEmail,
.settingsSection .accountingEmail {
  margin-top: 10px;
  margin-bottom: 6px;
}

.settingsSection .deliveryTimeWindow:last-child,
.settingsSection .deliveryInfo:last-child,
.settingsSection .onboardBuyerEmail:last-child,
.settingsSection .accountingEmail:last-child {
  margin-bottom: 0;
}

.settingsSection .deliveryTimeWindow > *,
.settingsSection .deliveryInfo > *,
.settingsSection .onboardBuyerEmail > *,
.settingsSection .accountingEmail > * {
  display: inline-block;
  vertical-align: top;
}

.settingsSection .deliveryTimeWindow .number,
.settingsSection .deliveryInfo .number,
.settingsSection .onboardBuyerEmail .number,
.settingsSection .accountingEmail .number {
  padding: 0;
  margin-top: 0px;
  width: 24px;
  height: 24px;
  margin-left: 18px;
}

.settingsSection .deliveryTimeWindow .number,
.settingsSection .deliveryTimeWindow .dayOfWeek,
.settingsSection .deliveryInfo .number,
.settingsSection .deliveryInfo .dayOfWeek,
.settingsSection .onboardBuyerEmail .number,
.settingsSection .onboardBuyerEmail .dayOfWeek,
.settingsSection .accountingEmail .number,
.settingsSection .accountingEmail .dayOfWeek {
  margin-right: 12px;
}

.settingsSection .deliveryTimeWindow .dayOfWeek,
.settingsSection .deliveryInfo .dayOfWeek,
.settingsSection .onboardBuyerEmail .dayOfWeek,
.settingsSection .accountingEmail .dayOfWeek {
  width: 100px;
}

.settingsSection .deliveryTimeWindow .time,
.settingsSection .deliveryInfo .time,
.settingsSection .onboardBuyerEmail .time,
.settingsSection .accountingEmail .time {
  width: 120px !important;
}

.settingsSection .deliveryTimeWindow .to,
.settingsSection .deliveryInfo .to,
.settingsSection .onboardBuyerEmail .to,
.settingsSection .accountingEmail .to {
  margin-left: 12px;
  margin-right: 12px;
}

.settingsSection .deliveryTimeWindow .enable,
.settingsSection .deliveryInfo .enable,
.settingsSection .onboardBuyerEmail .enable,
.settingsSection .accountingEmail .enable {
  margin-left: 24px;
}

.settingsSection .deliveryTimeWindows,
.settingsSection .onboardBuyerEmails,
.settingsSection .accountingEmails {
  margin-bottom: 12px;
}

.defaultVendorRegion {
  background-color: rgba(0, 255, 0, 0.1);
}

.back {
  margin-bottom: 24px;
}

.amountContainer {
  position: relative;
}

.amountContainer .transferredAmount {
  position: absolute;
  bottom: 28px;
  left: 0;
  right: 0;
  text-align: center;
  color: #444;
  font-size: 15px;
}

.regionAddButton {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
}

.regionAddButton > *:last-child {
  float: right;
}

.regionAddButton > *:first-child {
  float: none;
}

.regionAddButton:after {
  content: '';
  display: table;
  clear: both;
}

.maskedInput {
  border-bottom: 1px solid #E0E0E0;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0 !important;
  width: 350px;
}

.billingAddressSection .grid {
  width: 100%;
}

.billingAddressSection .field + .field {
  margin-top: 12px;
}

.billingAddressSection .field .label {
  font-weight: 500;
  display: block;
  text-align: right;
}

.billingAddressSection .field .select {
  height: 25px !important;
}

.billingAddressSection .field .select > div:nth-child(2) > div:first-child > div:nth-child(2) {
  line-height: 25px !important;
  top: 0 !important;
}

.billingAddressSection .field .select button {
  width: 25px !important;
  height: 25px !important;
  padding: 0 !important;
}

.billingAddressSection .field .select hr {
  bottom: -2px !important;
}

.billingAddressSection .field .text {
  height: 25px !important;
}

.billingAddressSection .field .text hr {
  bottom: -2px !important;
}

.billingAddressSection .field .multilineText {
  top: -12px !important;
}

.billingAddressSection hr {
  border-top: 1px solid #e0e0e0;
}

.dropdownMenu {
  max-width: 50%;
  bottom: 24px;
  margin-right: 24px;
}

.sectionBody {
  padding: 12px 24px;
}

.error {
  font-weight: 500;
  color: red;
}

.warning {
  font-weight: 500;
  color: #F28A35;
}

.warningColor {
  color: #F28A35;
}

.linkedStores {
  width: 100%;
}

.asyncSelect :global(.Select-control) {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 0 !important;
}

.enableOvernight {
  color: #D94E56;
  text-decoration: underline;
  font-weight: bold;
  font-size: 16px;
}
