.label {
    pointer-events: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
}

.value {
    color: #000;
}

.maskedInput {
    border-bottom: 1px solid #E0E0E0;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 340px;
    margin: 10px;
}

.input  {
    width: 340px !important;
    margin: 10px;
}
