.field {

  .label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 2px;
  }

  .value {
    color: #000;
  }
}

.releaseDate, .releaseTime {
  display: inline-block;
}

.releaseDate {
  margin-right: 12px;
}
