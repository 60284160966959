.option {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    .cardDigitsContainer{
        display: flex;
        .cardDigits {
            padding: 0 5px;
        }
    }
    &:hover {
        background-color: #edebe7;
    }
}

.invoicePaymentMethod {
    display: flex;
    gap: 10px;
    font-size: 14px;
    .paymentMethodBrand {
        text-transform: capitalize;
    }
}
