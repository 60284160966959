.settings {
  padding: 12px 24px;
  clear: both;
}

.vendor,
.buyer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.panel {
  margin-top: 20px;
  margin-bottom: 20px;
}

.label {
  display: inline-block;
  padding-top: 20px;
  font-weight: 500;
  padding-right: 20px;
  text-align: left;
}

.email {
  display: inline-block;
  padding-top: 20px;
  font-weight: 300;
  text-align: left;
}

.value {
  vertical-align: middle;
}

.text {
  height: 25px !important;
  hr {
    bottom: -2px !important;
  }
}

.actions {
  margin-top: 12px;
  > * {
    &:last-child {
      float: right;
    }
    &:first-child {
      float: none;
    }
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.addBuyerMemberWrapper {
  float: right;
}

.rowPadding {
  padding-top: 15px;
  padding-bottom: 10px;
}

.maskedInput {
  margin-top: 15px;
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
}

.deleteAccount {
  margin-right: 20px;
  text-align: right;
}
