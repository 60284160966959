.field {
  margin-top: 18px;

  .label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 6px;
  }
}

.recipients {
  margin-bottom: 12px;

  .recipient {
    margin-bottom: 6px;
    display: table;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    > * {
      display: table-cell;
      vertical-align: top;
    }

    .deleteContainer {
      width: 1px;
    }
  }
}

.noLabelText {
  height: 25px !important;

  hr {
    bottom: -2px !important;
  }
}
