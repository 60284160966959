.container {

  .loader {
    text-align: center;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
  }

  .emptyPlaceholder {
    padding: 8px 16px;
  }

  .buyers {
    padding-top: 8px;

    .buyer {
      background-color: #fff;
      border: none;
      outline: none;
      width: 100%;
      display: block;
      text-align: left;
      padding: 8px 16px;
    }
  }
}
