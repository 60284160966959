@import '../../styles/colors';

.container {

  .routeLoadingBar {
    position: fixed;
    left: 0;
    right: 0;
    top: 64px;
  }

  .appContent {
    margin-top: 64px;
    padding-left: 0;
    transition: padding-left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }

  &.isMenuOpen {

    .appContent {
      padding-left: 200px;
    }
  }

  &.embedded {

    .appBar {
      display: none;
    }

    .routeLoadingBar {
      top: 0;
    }

    .appContent {
      margin-top: 0;
      margin-left: -200px;
    }

    .drawer {
      display: none;
    }
  }
}

@media screen and (max-width: 480px)  {
  &.isMenuOpen {
    .appContent {
      padding-left: 150px !important;
    }
  }

  &.embedded {

    .appContent {
      margin-left: -150px !important;
    }
  }
}
