.label {
  pointer-events: none;
  user-select: none;
  font-size: 14px;
  font-weight: bold;
}

.subheader {
  color: #000;
  font-weight: 800;
  font-size: 16px;
}

.text,
.value {
  font-size: 10px;
}

.block {
  max-width: 250px;
}

.colInline {
  display: inline-block;
}

.operationalDaysHeader {
  padding: 20px 0;
  border-top: 1px dotted rgb(224, 224, 224);
}

.operationalDaysTableHeaders {
  > * {
    border: 1px solid rgb(224, 224, 224);
    border-bottom: none;
    padding: 15px;
    text-align: center;
  }
}

.deliveryTimeWindow,
.operationalDays {
  font-size: 12px;
  height: 48px;
  line-height: 48px;

  &:last-child {
    margin-bottom: 0;

    // last row is showing duplicate a row with a border, this is to remove that extra border
    .columns {
      > * {
        border: none;
      }
    }
  }

  > * {
    display: inline-block;
    vertical-align: top;
    text-align: center;

    .columns {
      > * {
        border: 1px solid rgb(224, 224, 224);
      }
    }
  }
  .number,
  .dayOfWeek {
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
  }
  .dayOfWeek {
    width: 100px;

    .cutOffDeliveryDay {
      div {
        text-transform: capitalize;
      }
    }
  }
  .dayOfWeekSelected {
    color: #333;
  }
  .time {
    font-size: 10px;
    width: 40% !important;
  }
  .enable {
    margin-left: 24px;
  }
}

.dropdown {
  width: 100% !important;
}

.toggle {
  margin-top: 40px;
}

hr {
  margin: 1em;
  border-width: 2px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

:global(.pac-container) {
  z-index: 1501 !important;
}

.byosOperationalDays {
  padding: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
