.container {
  border-bottom: 1px solid rgb(224, 224, 224);

  > form {
    display: table;
    width: 100%;

    > * {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .searchText {

    > input {
      width: 100%;
      height: 46px;
      padding: 0 24px 0 0;
      border: none;
      outline: none;
      background-color: #fff;
      color: #000;
    }
  }

  .searchSubmit {
    width: 1px;

    > button {
      border-radius: 0;
      background-color: #fff;
      width: 58px;
      height: 46px;
      border: none;
      outline: none;
      padding: 0;

      > i {
        font-size: 1.6em;
        line-height: 1.6em;
        color: #666;
      }
    }
  }

  .searchButtonSubmit {
    width: 10%;
    text-align: right;
  }

  .searchButton {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
}
