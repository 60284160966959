.container {
    padding: 25px 40px;
    .createOrderForm {
        margin-left: auto;
        margin-right: auto;
        >h2 {
            margin: 0;
            padding: 80px 55px 10px;
            background-color: #4BA1AA !important;
            color: #fff;
            font-weight: 300;
            font-size: 35px;
        }
    }
}

.fieldLabel {
    text-align: right;
    font-weight: 500;
    padding-right: 36px;
    padding-top: 16px;
}

.buttonNav {
    text-align: right;
    padding-right: 36px;
    padding-top: 16px;
}

.fieldValue {
    text-align: left;
    padding-top: 16px;
}

.grid {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
}

.dropdownMenu {
    // bottom: 16px;
    margin-right: 24px;
}

.date {
    max-width: 50%;
}

.submit {
    margin: 12px;
}

.details {
    .label {
        font-weight: 500;
        text-align: right;
        padding-right: 36px;
    }
}

.warning {
    color: rgb(255, 0, 0);
    font-size: 15px;
    letter-spacing: 0.5px;
}

.notes {
    border: 1px solid #e8e8e8;
    padding-top: 24px;
    padding-bottom: 12px;
    .label {
        color: #666;
    }
    .value {
        overflow: auto;
        width: 100%;
        height: 100px;
        background-color: transparent;
        resize: none;
        //padding: 5px 10px;
        border-radius: 4px;
        border-color: #888;
    }
}