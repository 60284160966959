@import '../../styles/colors';

.container {
  padding: 50px 15px 15px;

  .loginForm {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    > h2 {
      margin: 0;
      padding: 80px 55px 10px;
      background-color: $colour-primary-roquefort60;
      color: #fff;
      font-weight: 300;
      font-size: 35px;
    }
  }
}
