.container {
  .select {
    z-index: 1001;

    :global(.Select-control) {
      border-top: 0;
      border-left: 0;
      border-right: 0;
    }

    :global(.Select-value),
    :global(.Select-input),
    :global(.Select-placeholder) {
      padding-left: 0;
    }

    :global(.Select-placeholder) {
      color: rgba(0, 0, 0, 0.3);
    }

    :global(.Select-menu-outer) {
      margin: 0.5rem 0;
    }

    :global(.Select-option.is-focused),
    :global(.Select-option.is-selected) {
      background-color: #edebe7;
      color: #333;
    }
  }

  :global(.is-focused:not(.is-open)) > :global(.Select-control) {
    border-color: #15a154;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(21, 161, 84, 0.1);
  }
}

.dropdownMenu {
  max-width: 50%;
  bottom: 24px;
  margin-right: 24px;
}

.rowPadding {
  padding-top: 15px;
  padding-bottom: 10px;
}
