.label {
    pointer-events: none;
    user-select: none;
    font-size: 14px;
    font-weight: bold
}

.subheader {
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

.text,
.value {
    font-size: 10px;
}

.block {
    max-width: 250px;
}

.colInline {
    display: inline-block;
}

.deliveryTimeWindow,
.deliveryInfo {
    font-size: 12px;
    margin-bottom: 6px;
    height: 48px;
    line-height: 48px;
    &:last-child {
        margin-bottom: 0;
    }
    >* {
        display: inline-block;
        vertical-align: top;
    }
    .number,
    .dayOfWeek {
        margin-right: 12px;
        font-size: 14px;
        font-weight: 500;
    }
    .dayOfWeek {
        width: 100px;
    }
    .time {
        font-size: 10px;
        width: 40% !important;
    }
    .to {
        margin-left: 12px;
        margin-right: 12px;
        font-size: 14px;
    }
    .enable {
        margin-left: 24px;
    }
}

.dropdown {
    width: 100% !important;
}

.toggle {
    margin-top: 40px;
}

hr {
    margin: 1em;
    border-width: 2px;
}