.statuses {
    padding: 10px 16px;
}

.status {
    margin-top: 12px;
    >* {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
    }
    &:first-child {
        margin-top: 0;
        display: inline-flex;
    }
    .label {
        font-weight: 500;
        width: 150px;
    }

    .chips {
        margin-left: 8px !important;
    }
}

.invoiceDate {
    display: block;
    margin-top: 12px;
    >* {
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
    }
    &:first-child {
        margin-top: 0;
    }
    .label {
        font-weight: 500;
        width: 150px;
        margin-right: 24px;
    }
}

.details {
    .label {
        font-weight: 500;
        text-align: right;
        padding-right: 36px;
    }
}

.orderSection {
    .actions {
        padding-top: 12px;
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -24px;
            right: -24px;
            border-top: 1px solid #e0e0e0;
        }
    }
}

.field {
    .fieldLabel {
        text-align: right;
        font-weight: 500;
        padding-right: 20px;
        width: 250px;
    }
    .smallFieldLabel {
        text-align: right;
        font-weight: 500;
        padding-right: 36px;
        font-size: 14px;
    }
    button {
        >div {
            >div {
                height: 30px !important;
                >span {
                    font-size: 12px !important;
                    padding-left: 12px !important;
                    padding-right: 12px !important;
                }
            }
        }
    }
}

.grid {
    max-width: 90%;
}