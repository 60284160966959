@import '../../styles/colors';

.container {
  padding: 25px 55px;

  .actions {
    margin-top: 20px;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .submit {
      float: right;
    }
  }

  label:focus, label:hover {
    color: $colour-primary-roquefort80;
  }
}
