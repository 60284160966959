.field {
  margin-top: 18px;
  .label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 2px;
  }
  .value {
    color: #000;
  }
}

.notifyGroup {
  display: grid;
  gap: 1rem;
  align-items: flex-end;
  grid-template-columns: 1fr minmax(200px, auto);

  &.taxGroup {
    grid-template-columns: 1fr minmax(175px, auto) minmax(200px, auto);
  }
}

.labelMessage {
  font-style: italic;
  color: rgba(0, 0, 0, 0.3);
}

.reasonShow {
  display: block !important;
}

.reasonHide {
  display: none !important;
}

.thumbSwitched {
  background-color: 'red';
}

.trackSwitched {
  background-color: '#';
}

.labelStyle {
  color: 'red';
}
