.panelBody {
  padding: 12px 24px;
}
.grid {
  width: 100%;
  padding: 0;
}

.total {
 padding: 12px	24px;
  border-style: solid;
}
