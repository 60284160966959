.loader {
  text-align: center;
  padding-bottom: 12px;
}

.text {
  height: 19px !important;
  font-size: 13px !important;
  hr {
    bottom: -2px !important;
  }
}

.label {
  font-weight: bold;
}

.colLabel {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}

.colValue {
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.cardAction {
  width: 100%;
  text-align: right;
}

.actionChip {
  padding: 12px 18px !important;
  text-align: right;
  float: right;
}

.select {
  display: inline-block;
  font-size: 13px !important;
  button {
    padding: 0 !important;
    display: inline-block;
    vertical-align: top;
  }
  hr {
    bottom: -2px !important;
  }
}

.hasButton {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.dirty {
  background-color: #e3ffd2;
  &:hover {
    td {
      background-color: inherit;
    }
  }
}

.vendor-inactive-label {
  padding-left: 8px;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}
